import React, { useRef } from 'react'
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useFirebasePieces } from './ProvideAuth'

const LoginBlockBase = ({ classes, visible }) => {
  const { firebaseUiConfig, authCore } = useFirebasePieces()
  const widget = useRef(null);

  // useEffect(() => {
  //   console.log('cur', widget.current)
  // }, [visible])
  if (!authCore || !firebaseUiConfig) { return null }
  const augmentedFirebaseUiConfig = {
    ...firebaseUiConfig,
    callbacks: {
      signInSuccessWithAuthResult: (a, b) => {
      }
    }
  }
  const reaugment = (config) => {
    if (authCore.isSignInWithEmailLink(window.location.href)) {
      const reaugment = {...config, signInFlow: 'redirect'}
      return reaugment
    } else {
      return config
    }
  }
  const secondAugmentation = reaugment(augmentedFirebaseUiConfig)

  return (
    <Container maxWidth="sm" className={classes.loginWrapper} style={{display: (!visible && 'none') || 'block'}}>
      <Card variant="outlined" className={classes.loginCard}>
        <Typography variant="h6" align="center">Please log in</Typography>
        <StyledFirebaseAuth uiConfig={secondAugmentation} firebaseAuth={authCore} uiCallback={(innerWidget) => {widget.current = innerWidget}} />
      </Card>
    </Container>
  )
}

export const LoginBlock = withStyles({
  loginWrapper: {
    marginTop: '50px'
  },
  loginCard: {
    minHeight: '100px'
  }
})(LoginBlockBase)