import React from 'react'
import { useAuth, useFirebasePieces } from './ProvideAuth'
import { LoginBlock } from './LoginBlock'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { withStyles } from '@material-ui/core'
import { ProvideNavDetails, useNavDetails } from '../utils/MiscellaneousContexts'
import { BREADCRUMB_EVENT, BREADCRUMB_EVENT_NAME, BREADCRUMB_ORG, BREADCRUMB_ORG_NAME } from '../utils/Constants'
import every from 'lodash/every'
import toString from 'lodash/toString'
import { logging } from '../utils/logging'

const isNotEmptyString = (elem) => toString(elem) !== ''

const BreadCrumbs = ({ classes, history }) => {
  const { navDetails } = useNavDetails()

  if (!navDetails) {
    return null
  }

  const renderPiecesIfNonBlank = (pieces, name, isButton = false) => {
    if (isNotEmptyString(name) && every(pieces, isNotEmptyString)) {
      const href = `/${pieces.join('/')}`
      return (
        <Typography className={classes.breadcrumb}>
          {isButton ?
            (<Button href={href}>{name}</Button>) :
            (<a href={href} onClick={() => {logging.log('Trying to go to ', href); history.push(href)}} className={classes.breadcrumbLink}>{name}</a>)
            // (<Link to={href} className={classes.breadcrumbLink}>{name}</Link>)
          }
        </Typography>
      )
    }
  }

  return (
    <React.Fragment>
      {renderPiecesIfNonBlank([navDetails[BREADCRUMB_ORG]], navDetails[BREADCRUMB_ORG_NAME])}
      {renderPiecesIfNonBlank([navDetails[BREADCRUMB_ORG], navDetails[BREADCRUMB_EVENT]], navDetails[BREADCRUMB_EVENT_NAME])}
      {renderPiecesIfNonBlank([navDetails[BREADCRUMB_ORG], navDetails[BREADCRUMB_EVENT], 'admin'], 'Admin', true)}
    </React.Fragment>
  )
}

export const FirebaseLoginWrapperBase = ({ classes, history, children }) => {
  const auth = useAuth()
  const { authCore } = useFirebasePieces()

  return (
    <ProvideNavDetails>
      <AppBar position="fixed">
        <Toolbar>
          <BreadCrumbs classes={classes} history={history} />
          <div className={classes.spacer}></div>
          {(auth.user && 
            (<Button color="inherit" onClick={() => authCore.signOut()}>Sign-out</Button>)) || 
            (<Button color="inherit" onClick={() => auth.setIsSigningIn(true)}>Sign-In</Button>)
          }
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Container disableGutters className={classes.bodyWrapper}>
        <LoginBlock visible={(!auth.user && auth.isSigningIn)} />
        {children}
      </Container>
    </ProvideNavDetails>
  )
}

export const FirebaseLoginWrapper = withStyles(theme => ({
  bodyWrapper: {
  },
  spacer: {
    flexGrow: 1
  },
  breadcrumb: {
    marginRight: '10px',
    color: theme.palette.text.secondary,
  },
  breadcrumbLink: {
    color: theme.palette.text.secondary,
  }
}))(FirebaseLoginWrapperBase)