import polyglotI18nProvider from 'ra-i18n-polyglot'

import englishMessages from 'ra-language-english'
import { domainMessages as englishUSDomainMessages } from './domain/en_us'
import { logging } from '../logging'

export const i18nProvider = polyglotI18nProvider(locale => {
  logging.log('domain: ', englishUSDomainMessages)
  return {...englishMessages, ...englishUSDomainMessages}
  // NOTE: Additional, non-English languages can be added here, with the import().then functionality
  //   See https://marmelab.com/react-admin/Translation.html#lazy-loading-locales
}, 'en', {allowMissing: true})