import { createMuiTheme } from '@material-ui/core/styles';

const colors = {
  gold: '#D9B310',
  dkBlue: '#082e47',
  medBlue: '#0B3C5D',
  ltBlue: '#326CA1',
  cream: '#FAFAFF',
  blueBlack: '#1D2731'
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.medBlue
    },
    secondary: {
      main: colors.ltBlue
    },
    background: {
      default: '#f1f1f6',
      paper: colors.cream,
      dark: colors.medBlue,
      darker: colors.dkBlue
    },
    text: {
      primary: colors.blueBlack,
      secondary: colors.cream
    },
    accent: {
      main: colors.gold
    }
  },
  overrides: {
    MuiButton: {
      root: {
        backgroundColor: colors.medBlue,
        borderWidth: '1px',
        borderColor: colors.gold,
        borderStyle: 'solid',
      },
      text: {
        color: colors.cream
      },
      textPrimary: {
        color: colors.cream
      }
    },
    MuiFormLabel: {
      root: {
        color: colors.blueBlack
      }
    },
    MuiInputLabel: {
      root: {
        color: '#1D2731'
      }
    },
    RaMenuItemLink: {
      root: {
        color: '#1D2731'
      },
      active: {
        color: '#D9B310'
      }
    },
    RaFilterFormInput: {
      body: {
        flexGrow: 1
      }
    },
    RaFilterForm: {
      form: {
        flexGrow: 1
      }
    },
    MuiRadio: {
      root: {
        color: colors.medBlue
      }
    }
  }
})
