export const CORE_SUBDOMAIN = 'www'
export const ADMIN_PATH = 'admin'

export const BREADCRUMB_EVENT = 'BREADCRUMB_EVENT'
export const BREADCRUMB_EVENT_NAME = 'BREADCRUMB_EVENT_NAME'
export const BREADCRUMB_ORG = 'BREADCRUMB_ORG'
export const BREADCRUMB_ORG_NAME = 'BREADCRUMB_ORG_NAME'

export const ATTENDEE_STATES = {
  ACTIVE: 'ACTIVE',
  REFUNDED: 'REFUNDED'
}
