import React, { createContext, useContext, useState, useCallback } from 'react'

const crossLinkContext = createContext({ links: {} })

export const CrossLinkWrapper = ({ children }) => {
  const crossLink = useCrossLinksInt()
  return (
    <crossLinkContext.Provider value={crossLink}>
      {children}
    </crossLinkContext.Provider>
  )
}

export const useCrossLinks = () => {
  return useContext(crossLinkContext)
}

const useCrossLinksInt = () => {
  const [links, setLinks] = useState({})

  const addLink = useCallback((identifier, details) => {
    setLinks((currentLinks) => ({
      ...currentLinks,
      [identifier]: details
    }))
  }, [setLinks])
  const deleteLink = useCallback((identifier) => {
    setLinks((currentLinks) => {
      delete currentLinks[identifier]
      return currentLinks
    })
  }, [setLinks])

  return {
    links: Object.values(links).sort((a, b) => (a.priority || 100) - (b.priority || 100)),
    addLink,
    deleteLink
  }
}