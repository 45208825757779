import { useRef, useEffect, useCallback } from 'react'
import OperationsErrorReporting from 'stackdriver-errors-js'
import CryptoJS from 'crypto-js'
import { useSettings } from './useSettings'

const LOCAL_STORAGE_KEY = '__errorTempId'

export const useOperationsErrorHandler = () => {
  const settings = useSettings()
  const errorHandlerRef = useRef()
  useEffect(() => {
    console.dir(settings)
    console.log('Settings in ErrorReporting', JSON.stringify(settings))
    const theHandler = new OperationsErrorReporting()
    theHandler.start({
      key: settings.FIREBASE_CFG.apiKey,
      projectId: settings.FIREBASE_CFG.projectId
    })
    errorHandlerRef.current = theHandler
  }, [settings])
  const setUser = useCallback((user) => {
    if (user && user.user_id) {
      errorHandlerRef.current.setUser(user.user_id)
    } else {
      const tempId = localStorage.getItem(LOCAL_STORAGE_KEY, CryptoJS.lib.WordArray.random(16))
      localStorage.setItem(LOCAL_STORAGE_KEY, tempId)
      errorHandlerRef.current.setUser(`temp-${tempId}`)
    }
  }, [])
  return {
    setUser,
    errorHandler: errorHandlerRef.current
  }
}
