export const domainMessages = {
  payments: {
    complimentary: {
      checkout: 'Checkout'
    },
    square: {
      checkout: 'Checkout with Square\u00AE',
      header: 'Checkout via Square'
    }
  },
  resources: {
    attendees: {
      fields: {
        details: {
          first_name: "First Name",
          last_name: "Last Name",
          email: 'Email',
          date_of_birth: 'Date of Birth'
        },
        state: 'State',
        timestamps: {
          registered: 'Registered At'
        }
      }
    },
    verifications: {
      fields: {
        id: 'Identifier',
        details: {
          title: 'Title'
        }
      }
    }
  },
  myconreg: {
    admin: {
      event: {
        dates: {
          duration: {
            starts_at: 'Event starts at',
            ends_at: 'and ends at',
          },
          publicly_visible: {
            starts_at: 'Event is publicly visible starting at',
            ends_at: 'and is no longer visible at'
          },
          onsale: {
            starts_at: 'Event goes on-sale at',
            ends_at: 'and goes off-sale at'
          }
        },
        questions: {
          yesno: 'Yes/No',
          mchoice: 'Multiple Choice',
          shorttext: 'Short Text Entry',
          longtext: 'Multiple-line Text Entry'
        },
        verifications: {
          fields: {
            yesno: 'Yes/No',
            mchoice: 'Multiple Choice',
            shorttext: 'Short Text Entry',
            photo: 'Photo from Camera'
          }
        },
        triggers: {
          actions: {
            slack: 'Slack',
            email: 'E-Mail',
            webhook: 'Webhook',
            pubsub: 'PubSub'
          },
          events: {
            attendee_registered: 'When an attendee registers',
            order_complete: 'When an order is completed',
            attendee_checked_in: 'When a user is checked in on-site',
            nightly_heartbeat: 'Run every night (use with condition for a scheduled email)'
          }
        }
      }
    },
    forms: {
      select: {
        prompt: 'Please select a value'
      }
    },
    cart: {
      required: 'Required',
      merchandise: {
        header: 'Merchandise'
      },
      questions: {
        yesno: {
          yes: 'Yes',
          no: 'No'
        }
      },
      dates: {
        months: {
          1: 'January',
          2: 'February',
          3: 'March',
          4: 'April',
          5: 'May',
          6: 'June',
          7: 'July',
          8: 'August',
          9: 'September',
          10: 'October',
          11: 'November',
          12: 'December'
        }
      },
      errors: {
        must_not_be_blank: 'Must not be blank',
        must_be_valid_date: 'Must be a valid date',
        invalid_ticket: 'Please choose a valid ticket',
        ticket_not_onsale: 'The selected ticket is not currently onsale, please choose another',
        invalid_format: 'Must be a valid phone number',
        cart_has_errors: 'There are errors with the cart',
        too_few_attendees: 'More attendees are required for this ticket',
        too_many_attendees: 'There are too many attendees for this ticket',
        unknown_error: 'Failed to submit payment due to unknown error. If you feel this is in error, please contact michael@jmts-llc.com'
      }
    },
    orders: {
      no_orders: 'You have no completed orders for this event',
      header: 'My Orders for %{orgName} - %{eventName}',
      ordered_at: 'Ordered at %{datetime}'
    },
    redemption: {
      success: 'You have successfully redeemed a code.'
    }
  }
}