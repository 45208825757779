import React, { useState, useEffect, createContext, useContext } from 'react'

const SettingsContext = createContext()

export const useSettings = () => useContext(SettingsContext)

export const ProvideSettings = ({children}) => {
  const [theSettings, setTheSettings] = useState(null)
  useEffect(() => {
    fetch('/settings.json').then(resp => resp.json()).then(data => {
      setTheSettings(data)
    })
  }, [setTheSettings])
  if (!theSettings) {
    return null
  }
  return (
    <SettingsContext.Provider value={theSettings}>
      {children}
    </SettingsContext.Provider>
  )
}