import React, { useState, useEffect, useContext, createContext } from "react";
import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/firestore'
import "firebase/performance";
import without from 'lodash/without'
import { logging } from '../utils/logging'
import { useOperationsErrorHandler } from '../utils/ErrorReporting'
import { useSettings } from "../utils/useSettings";

const firebaseContext = createContext();
const authContext = createContext();

const AuthProvideWrapper = ({ children }) => {
  const auth = useProvideAuthInt();
  if (!auth.authLoaded) {
    return null
  }
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  )
}

export function ProvideFullAuth({ children }) {
  const firebasePieces = useFirebasePiecesInt();
  return (
    <firebaseContext.Provider value={firebasePieces}>
      <AuthProvideWrapper>
        {children}
      </AuthProvideWrapper>
    </firebaseContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(authContext);
};

export const useFirebasePieces = () => {
  return useContext(firebaseContext)
}


const firebaseSignInOptions = (is_limited) => {
  const _googleSignInOption = () => {
    if (is_limited) { return null }
    return {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        prompt: 'select_account'
      }
    }
  }
  const _emailSignInOption = () => {
    return {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      emailLinkSignIn: () => {
        logging.log('Receiving email link sign in')
        window.localStorage.setItem('redirectAfterSignIn', window.location.href)
        return {}
      }
    }
  }
  // TODO: Add Facebook
  return without([
    _googleSignInOption(),
    _emailSignInOption()
  ], null)
}

const useFirebasePiecesInt = () => {
  const [authCore, setAuthCore] = useState(null)
  const [firebaseApp, setFirebaseApp] = useState(null)
  const settings = useSettings()
  useEffect(() => {
    if (!settings) { return null }
    if (!window.mcrFirebaseInstance) {
      window.mcrFirebaseInstance = firebase.initializeApp(settings.FIREBASE_CFG)
    }
    setFirebaseApp(window.mcrFirebaseInstance)
    firebase.analytics();
    // TODO: Might need to capture this output to use later if we want to do custom code traces
    firebase.performance();
    const db = firebase.firestore()
    if (process.env.REACT_APP_FIRESTORE_EMULATOR_HOST) {
      db.useEmulator(...process.env.REACT_APP_FIRESTORE_EMULATOR_HOST.split(':'))
    }
    const authCore = firebase.auth()
    authCore.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    setAuthCore(authCore)
  }, [settings])
  const firebaseUiConfig = {
    signInFlow: 'popup',
    signInOptions: firebaseSignInOptions(settings.IS_LIMITED)
  }
  logging.log(firebaseUiConfig)

  return {
    authCore,
    firebaseApp,
    firebaseUiConfig
  }
}

const useProvideAuthInt = () => {
  const [user, setUser] = useState(false)
  const [profileLoaded, setProfileLoaded] = useState(false)
  const [detailedPerms, setDetailedPerms] = useState({orgs: {admin: [], view: []}, events: {admin: [], view: []}})
  const [isSigningIn, setIsSigningIn] = useState(false)
  const [authLoaded, setAuthLoaded] = useState(false)
  const {authCore, firebaseApp} = useFirebasePieces()
  const { setUser: setErrorUser } = useOperationsErrorHandler()

  const setEmptyPerms = () => {
    localStorage.setItem('__authPerms', JSON.stringify({orgs: {admin: [], view: []}, events: {admin: [], view: []}}))
    setDetailedPerms({orgs: {admin: [], view: []}, events: {admin: [], view: []}})
  }

  useEffect(() => {
    if (authCore) {
      authCore.onAuthStateChanged(user => {
        setErrorUser(user)
        setAuthLoaded(true)
        setUser(user)
        setEmptyPerms()
        if (user && user.uid) {
          setIsSigningIn(false)
          setProfileLoaded(false)
          localStorage.removeItem('__profileLoaded')
          const fs = firebaseApp.firestore()
          const userRef = fs.collection("users").doc(user.uid)
          userRef.get().then((userDoc) => {
            if (userDoc.exists) {
              const data = userDoc.data()
              const assemblage = {
                orgs: {
                  admin: Object.keys(data.organizations || {}),
                  view: [] // Not currently implemented
                },
                events: {
                  admin: Object.keys(data.eventAdmins || {}),
                  view: Object.keys(data.eventViewers || {}),
                }
              }
              localStorage.setItem('__authPerms', JSON.stringify(assemblage))
              setDetailedPerms(assemblage)
            }
            localStorage.setItem('__profileLoaded', '1')
            setProfileLoaded(true)
            const redirectTo = localStorage.getItem('redirectAfterSignIn')
            localStorage.removeItem('redirectAfterSignIn')
            if (redirectTo) {
              window.location = redirectTo
            }
          })
        }
      })
    }
  }, [authCore, firebaseApp, setErrorUser])

  return {
    user,
    profileLoaded,
    isSigningIn,
    setIsSigningIn,
    authLoaded,
    ...buildAbilities(detailedPerms)
  };
}

export const buildAbilities = (detailedPerms) => {
  const canAdminOrg = (orgShort) => detailedPerms.orgs && detailedPerms.orgs.admin.indexOf(orgShort) >= 0
  const canViewOrgAdmin = (orgShort) => canAdminOrg(orgShort) || (detailedPerms.orgs && detailedPerms.orgs.view.indexOf(orgShort) >= 0)
  const canAdminEvent = (orgShort, eventPath) => canAdminOrg(orgShort) || (detailedPerms.events && detailedPerms.events.admin.indexOf(`${orgShort}__${eventPath}`) >= 0)
  const canViewEventAdmin = (orgShort, eventPath) => canViewOrgAdmin(orgShort) || canAdminEvent(orgShort, eventPath) || (detailedPerms.events && detailedPerms.events.view.indexOf(`${orgShort}__${eventPath}`) >= 0)

  return {
    canAdminOrg,
    canViewOrgAdmin,
    canAdminEvent,
    canViewEventAdmin
  }
}