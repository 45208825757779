import React from 'react'
import { useOperationsErrorHandler } from './ErrorReporting'

export const HelpfulError = ({ error, prefix = '' }) => {
  const { errorHandler } = useOperationsErrorHandler()
  if (!errorHandler) { return false }
  console.log('Catching Error at prefix: ', prefix, '. Error is ', error)
  errorHandler.report(error)
  return (
    <div role="alert">
      <p>Something went wrong.</p>
      <p>Please contact Michael at michael@jmts-llc.com to help resolve your issue.</p>
    </div>
  )
}

export const helpfulHoist = (prefix) => (props) => <HelpfulError prefix={prefix} {...props} />