import React, { Suspense, lazy } from 'react';
import { Router, Switch, Route, Redirect, useParams } from 'react-router-dom'
import { createBrowserHistory as createHistory } from 'history'
import { ThemeProvider } from '@material-ui/core/styles'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorBoundary } from 'react-error-boundary'

import { TranslationProvider, Loading } from 'react-admin'

import { theme } from './styling/theme'
import { ProvideFullAuth } from './auth/ProvideAuth'
import { PrivateRoute } from './auth/PrivateRoute'
import { FirebaseLoginWrapper } from './auth/FirebaseLoginWrapper'
import { CrossLinkWrapper } from './utils/crossLinkContext'
import { i18nProvider } from './utils/i18n'
import { helpfulHoist } from './utils/HelpfulError'
import { ProvideSettings } from './utils/useSettings';

const LazyMarketingHome = lazy(() => import('./marketing/MarketingHome'))
const LazyPrivacyPage = lazy(() => import('./marketing/PrivacyPage'))
const LazyTermsOfService = lazy(() => import('./marketing/TermsOfService'))
const LazyCentralAdmin = lazy(() => import('./central_admin/CentralAdmin'))
const LazyOrgAdmin = lazy(() => import('./org_admin/OrgAdmin'))
const LazyEventAdmin = lazy(() => import('./event_admin/EventAdmin'))
const LazyEventViewHome = lazy(() => import('./event_view/EventViewHome'))
const LazyOrgViewHome = lazy(() => import('./org_view/OrgViewHome'))

const RouteParamWrapper = ({component, addlParams = {}, ...rest}) => {
  const routeParams = useParams()
  return React.createElement(component, {...rest, ...routeParams, ...addlParams})
}

export const App = () => {
  const history = createHistory()
  return (
    <ThemeProvider theme={theme}>
      <ProvideSettings>
        <ErrorBoundary fallbackRender={helpfulHoist("top")}>
          <TranslationProvider i18nProvider={i18nProvider}>
            <CrossLinkWrapper>
              <ProvideFullAuth>
                <FirebaseLoginWrapper history={history}>
                  <ErrorBoundary fallbackRender={helpfulHoist("topRouter")}>
                    <Router history={history}>
                      <Suspense fallback={<Loading />}>
                        <Switch>
                          <Route exact path="/">
                            <Redirect to="/welcome" />
                          </Route>
                          <Route path="/welcome" component={LazyMarketingHome} />
                          <Route path="/privacy" component={LazyPrivacyPage} />
                          <Route path="/terms-of-service" component={LazyTermsOfService} />
                          <PrivateRoute path="/admin" component={LazyCentralAdmin} />
                          <PrivateRoute path="/:orgShort/admin" render={() => <RouteParamWrapper component={LazyOrgAdmin} />} />
                          <PrivateRoute path="/:orgShort/:eventPath/admin" render={() => <RouteParamWrapper component={LazyEventAdmin} />} />
                          <Route path="/:orgShort/:eventPath" render={() => <RouteParamWrapper component={LazyEventViewHome} />} />
                          <Route path="/:orgShort" render={() => <RouteParamWrapper component={LazyOrgViewHome} />} />
                        </Switch>
                      </Suspense>
                    </Router>
                    <ToastContainer />
                  </ErrorBoundary>
                </FirebaseLoginWrapper>
              </ProvideFullAuth>
            </CrossLinkWrapper>
          </TranslationProvider>
        </ErrorBoundary>
      </ProvideSettings>
    </ThemeProvider>      
  )
}

export default App;
