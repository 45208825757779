import React, { useState, useContext, createContext, useCallback } from 'react'

export const PendingAndSavingContext = createContext()
export const EventDetailsContext = createContext({})
export const OrgShortContext = createContext('')

// ------------------------------------
// NavDetails
// ------------------------------------

const useNavDetailsInt = () => {
  const [navDetails, setNavDetails] = useState({})
  const setNavPieces = useCallback((newData) => {
    setNavDetails(innerNavData => ({...innerNavData, ...newData}))
  }, [setNavDetails])
  const setNavPiece = useCallback((piece, value) => setNavPieces({ [piece]: value }), [setNavPieces])

  return {
    navDetails,
    setNavPiece,
    setNavPieces
  }
}
const NavDetailsContext = createContext()
export const useNavDetails = () => useContext(NavDetailsContext)
export const ProvideNavDetails = ({ children }) => {
  const navDetails = useNavDetailsInt()
  return (
    <NavDetailsContext.Provider value={navDetails}>
      {children}
    </NavDetailsContext.Provider>
  )
}