import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useAuth } from './ProvideAuth'

// This component exists to defer the loading of the login component on a Private Route
const DummySetter = () => {
  const { setIsSigningIn } = useAuth();
  useEffect(() => {
    setIsSigningIn(true)
  }, [setIsSigningIn])
  return null
}

export const PrivateRoute = ({ children, component = null, render = null, ...rest}) => {
  const auth = useAuth();

  const cleanlyRender = () => {
    if (render) {
      return render(rest)
    }
    if (component) {
      return React.createElement(component, rest)
    }
    if (children) {
      if (typeof children === "function") {
        return children(rest)
      }
      return children
    }
  }

  return (
    <Route
      {...rest}
      render={() => {
        if (!auth.authLoaded) {
          return null
        }
        if (auth.user) {
          return (
            <React.Fragment>{cleanlyRender()}</React.Fragment>
          )
        }
        return (<DummySetter />)
      }}
    />
  )
}